<template>
  <v-container fluid>
    <h1 class="ma-2 ml-0">Documentos</h1>
    <div v-if="documentList.length">
      <v-row class="ma-2">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="text-capitalize"
          @click="documentUpload()"
        >
          <v-icon>{{ icons.mdiUpload }}</v-icon
          >Importar Documento
        </v-btn>
      </v-row>
      <v-divider></v-divider>
      <div>
        <v-row v-if="documentList.length" class="pa-2" dense>
          <v-col>
            <v-tabs color="deep-purple" left>
              <v-tab>Gerais</v-tab>
              <v-tab>Constitutivos</v-tab>
              <v-tab>Certidões</v-tab>
              <v-tab>Guias</v-tab>

               <!-- outros -->
              <v-tab-item>
                <v-container>
                  <v-row class="mt-3">
                    <v-col
                      v-for="row of documentList"
                      :key="row.id"
                      class="col-md-3 pa-2"
                      v-if="row.type == 'others'"
                    >
                      <v-card class="card">
                        <v-card-title>
                          {{ row.name }}
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="error"
                            @click="deleteDocument(row.id)"
                          >
                            <v-icon>{{ icons.mdiDelete }}</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-subtitle
                          >Valido até {{ row.due_at | dateBR }}</v-card-subtitle
                        >
                        <v-card-text
                          class="d-flex justify-center align-center"
                          style="height: 100px"
                        >
                          <v-icon large>{{
                            resolveIconFile(row.extension)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            block
                            plain
                            color="accent"
                            @click="getFile(row.id)"
                            >Visualizar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!-- Constitutivos -->
              <v-tab-item>
                <v-container>
                  <v-row class="mt-3">
                    <v-col
                      v-for="row of documentList"
                      :key="row.id"
                      class="col-md-3 pa-2"
                      v-if="row.type == 'legal'"
                    >
                      <v-card class="card">
                        <v-card-title>
                          {{ row.name }}
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="error"
                            @click="deleteDocument(row.id)"
                          >
                            <v-icon>{{ icons.mdiDelete }}</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-subtitle
                          >Valido até {{ row.due_at | dateBR }}</v-card-subtitle
                        >
                        <v-card-text
                          class="d-flex justify-center align-center"
                          style="height: 100px"
                        >
                          <v-icon large>{{
                            resolveIconFile(row.extension)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            block
                            plain
                            color="accent"
                            @click="getFile(row.id)"
                            >Visualizar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!-- Certidões -->
              <v-tab-item>
                <v-container>
                  <v-row class="mt-3">
                    <v-col
                      v-for="row of documentList"
                      :key="row.id"
                      class="col-md-3 pa-2"
                      v-if="row.type == 'certificate'"
                    >
                      <v-card class="card">
                        <v-card-title>
                          {{ row.name }}
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="error"
                            @click="deleteDocument(row.id)"
                          >
                            <v-icon>{{ icons.mdiDelete }}</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-subtitle
                          >Valido até {{ row.due_at | dateBR }}</v-card-subtitle
                        >
                        <v-card-text
                          class="d-flex justify-center align-center"
                          style="height: 100px"
                        >
                          <v-icon large>{{
                            resolveIconFile(row.extension)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            block
                            plain
                            color="accent"
                            @click="getFile(row.id)"
                            >Visualizar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!-- Guias -->
              <v-tab-item>
                <v-container>
                  <v-row class="mt-3">
                    <v-col
                      v-for="row of documentList"
                      :key="row.id"
                      class="col-md-3 pa-2"
                      v-if="row.type == 'financial'"
                    >
                      <v-card class="card">
                        <v-card-title>
                          {{ row.name }}
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="error"
                            @click="deleteDocument(row.id)"
                          >
                            <v-icon>{{ icons.mdiDelete }}</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-subtitle
                          >Valido até {{ row.due_at | dateBR }}</v-card-subtitle
                        >
                        <v-card-text
                          class="d-flex justify-center align-center"
                          style="height: 100px"
                        >
                          <v-icon large>{{
                            resolveIconFile(row.extension)
                          }}</v-icon>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            block
                            plain
                            color="accent"
                            @click="getFile(row.id)"
                            >Visualizar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </div>
    </div>
    <nothing-component v-if="!documentList.length && !loading">
      <v-btn
        color="success"
        class="text-capitalize ma-4"
        @click="documentUpload()"
      >
        <v-icon>{{ icons.mdiUpload }}</v-icon
        >Importar Documento
      </v-btn>
    </nothing-component>
    <document-form ref="documentForm" @updated="getDocuments()"></document-form>
  </v-container>
</template>

<script>
import Filter from "@/util/Filters";
import DocumentForm from "@/components/documents/form/DocumentForm";
import NothingComponent from "@/components/nothingComponent.vue";
import {
  mdiFileDocument,
  mdiUpload,
  mdiDelete,
  mdiFilePdf,
  mdiFileExcel,
  mdiImage,
  mdiFileWord,
} from "@mdi/js";
import axios from "axios";

export default {
  components: { DocumentForm, NothingComponent },

  data() {
    return {
      loading: false,
      documentList: [],

      icons: {
        mdiFileDocument,
        mdiUpload,
        mdiDelete,
        mdiFilePdf,
        mdiFileExcel,
        mdiImage,
        mdiFileWord,
      },
    };
  },

  mounted() {
    this.getDocuments();
  },

  methods: {
    getDocuments() {
      this.loading = true;

      this.$http
        .index("/document")
        .then((response) => {
          this.documentList = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    getFile(id) {
      this.$http
        .$get("/document/" + id, { company_id: this.company_id })
        .then(
          (response) => {
            window.open(response, "_blank");
          },
          (response) => {
            console.warn("error from download_contract");
            console.log(response);
            // Manage errors
          }
        )
        .catch((error) => {
          this.loading = false;
        });
    },

    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },

    // TODO ainda falta implementar a exclusão de documentos
    deleteDocument(id) {
      this.$http
        .$delete("/document/" + id)
        .then((res) => {
          console.log(res);
          this.getDocuments();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    documentUpload() {
      this.$refs.documentForm.open();
    },

    resolveIconFile(extension) {
      if (extension === "pdf") return this.icons.mdiFilePdf;
      if (extension === "png") return this.icons.mdiImage;
      if (extension === "xlsx") return this.icons.mdiFileExcel;
      if (extension === "docx") return this.icons.mdiFileWord;

      return this.icons.mdiFileDocument;
    },
  },
};
</script>

<style scoped>
.cardColor {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: white !important;
}
</style>
